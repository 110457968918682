@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-optical-sizing: auto;
  font-style: normal;
  color: #f9fafb;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}
